import clsx from "clsx";

import styles from "@/components/ErrorFallback/ErrorFallback.module.css";

type Props = {
    displayAutomaticReportInfo?: boolean;
};

export const ErrorFallback = ({ displayAutomaticReportInfo = false }: Props) => {
    return (
        <div className={clsx(styles.error_fallback)}>
            <h1>Something went wrong :(</h1>
            {displayAutomaticReportInfo && <p>This issue has been automatically reported</p>}
            <a href="/">Go to homepage</a>
        </div>
    );
};
