import { Tooltip } from "@datamole/wds-component-tooltip";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";

import { AuthenticationGuard } from "@/components/AuthenticationGuard/AuthenticationGuard";
import { AuthenticationProvider } from "@/components/AuthenticationProvider/AuthenticationProvider";
import { ErrorFallback } from "@/components/ErrorFallback";
import { Layout } from "@/components/Layout";
import { config } from "@/config";
import { pageAtRouteMap } from "@/pages";
import { routeNames, routes } from "@/routes";
import { extendRoutesWithSentry, initializeSentry, reportBoundaryErrorToSentry } from "@/services/sentry";
import { store } from "@/store";

import "@datamole/wds-css-normalize";
import "@datamole/wds-css-theme-fonterra";
import "@/styles/global.css";

const TOOLTIP_DELAY_DURATION_MS = 350;

const pages = routeNames.map((routeName) => {
    const Component = pageAtRouteMap[routeName];
    return { path: routes[routeName].path, Component };
});

initializeSentry();

const RoutesWithSentry = extendRoutesWithSentry(Routes);

const App = () => {
    return (
        <ErrorBoundary
            fallback={<ErrorFallback displayAutomaticReportInfo={config.SENTRY_DSN !== undefined} />}
            onError={reportBoundaryErrorToSentry}
        >
            <AuthenticationProvider>
                <Provider store={store}>
                    <Tooltip.Provider delayDuration={TOOLTIP_DELAY_DURATION_MS}>
                        <BrowserRouter>
                            <RoutesWithSentry>
                                <Route
                                    element={
                                        <AuthenticationGuard>
                                            <Layout>
                                                <Suspense>
                                                    <Outlet />
                                                </Suspense>
                                            </Layout>
                                        </AuthenticationGuard>
                                    }
                                >
                                    <Route index element={<Navigate to={routes.calculation.path} replace />} />
                                    {pages.map(({ path, Component }) => (
                                        <Route key={path} path={path} element={<Component />} />
                                    ))}
                                </Route>
                            </RoutesWithSentry>
                        </BrowserRouter>
                    </Tooltip.Provider>
                </Provider>
            </AuthenticationProvider>
        </ErrorBoundary>
    );
};

export { App };
